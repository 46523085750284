<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_12095_2945)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00035 14.0248C11.5123 14.0248 14.0248 11.5123 14.0248 8.00035C14.0248 4.48839 11.5123 1.97592 8.00035 1.97592C4.48839 1.97592 1.97592 4.48839 1.97592 8.00035C1.97592 11.5123 4.48839 14.0248 8.00035 14.0248ZM8.00035 15.364C6.0474 15.364 4.17443 14.5882 2.79348 13.2072C1.41253 11.8263 0.636719 9.95331 0.636719 8.00035C0.636719 6.0474 1.41253 4.17443 2.79348 2.79348C4.17443 1.41253 6.0474 0.636719 8.00035 0.636719C9.95331 0.636719 11.8263 1.41253 13.2072 2.79348C14.5882 4.17443 15.364 6.0474 15.364 8.00035C15.364 9.95331 14.5882 11.8263 13.2072 13.2072C11.8263 14.5882 9.95331 15.364 8.00035 15.364ZM5.51046 6.16436C5.55956 4.92334 6.40686 3.98472 7.99152 3.98472C9.43479 3.98472 10.4068 4.84185 10.4068 6.01119C10.4068 6.8585 9.98068 7.45446 9.25904 7.88646C8.5541 8.30079 8.35185 8.59043 8.35185 9.15301V9.48683H7.22079L7.2149 9.04992C7.18839 8.27919 7.52123 7.7873 8.27036 7.33959C8.93112 6.93999 9.16577 6.62286 9.16577 6.06028C9.16577 5.44272 8.67977 4.9901 7.93163 4.9901C7.17661 4.9901 6.69061 5.44272 6.64152 6.16436H5.51046ZM7.79417 12.016C7.70067 12.0178 7.60774 12.001 7.52085 11.9664C7.43396 11.9318 7.35485 11.8802 7.28817 11.8146C7.2215 11.749 7.1686 11.6708 7.13258 11.5845C7.09656 11.4982 7.07815 11.4055 7.07843 11.312C7.07843 10.9134 7.39555 10.6071 7.79515 10.6071C8.20457 10.6071 8.51581 10.9134 8.51581 11.312C8.51651 11.4062 8.49821 11.4996 8.46198 11.5865C8.42575 11.6735 8.37235 11.7522 8.30497 11.818C8.23759 11.8838 8.15762 11.9354 8.06985 11.9696C7.98208 12.0038 7.88832 12.0189 7.79417 12.016Z"
        fill="#4199EC"
      />
    </g>
    <defs>
      <clipPath id="clip0_12095_2945">
        <rect
          width="14.7273"
          height="14.7273"
          fill="white"
          transform="translate(0.636719 0.636719)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'QuestionIcon'
}
</script>
